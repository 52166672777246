import React, { useState } from 'react';
import { FaFileExcel, FaFilePdf, FaTimes } from 'react-icons/fa';
import { validateEmail } from '../../utils/mail';
import './ModalExport.css';

export function ExportModal({ onClose, onExportExcel, onExportPDF }) {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showPdfInput, setShowPdfInput] = useState(false);
  const [email, setEmail] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [pdfSubtitle, setPdfSubtitle] = useState('');
  const [sortOption, setSortOption] = useState('veiculo');
  const [includeTitleAndSubtitle, setIncludeTitleAndSubtitle] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleExcelClick = () => {
    setShowEmailInput(true);
    setShowPdfInput(false);
  };

  const handlePdfClick = () => {
    setShowPdfInput(true);
    setShowEmailInput(false);
  };

  const handleGenerateExcel = () => {
    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      setTimeout(() => setError(''), 4000);
      return;
    }

    onExportExcel(email);
    onClose();
  };

  const handleGeneratePDF = () => {
    const title = pdfTitle;
    const subtitle = pdfSubtitle;

    onExportPDF(title, subtitle, sortOption, includeTitleAndSubtitle);
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Exportar</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          {!showEmailInput && !showPdfInput ? (
            <>
              <button className="export-button" onClick={handleExcelClick}>
                <FaFileExcel className="icon" />
                <span>Exportar Excel</span>
              </button>
              <button className="export-button" onClick={handlePdfClick}>
                <FaFilePdf className="icon" />
                <span>Exportar PDF</span>
              </button>
            </>
          ) : showEmailInput ? (
            <div className="email-input-section">
              <div className="container-export-excel">
                <div className="filter-email-export-excel">
                  <div className="filter-email-export-excel-text">E-mail para envio</div>
                  <input
                      type="email"
                      id="email"
                      placeholder="Digite o e-mail para envio da planilha"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="email-input-export-excel"
                  />
                </div>
              </div>
              <div className='container-button-export-excel'>
                <button className="generate-button-export-excel" onClick={handleGenerateExcel}>
                  Gerar Excel
                </button>
              </div>
            </div>
          ) : (
            <div className="pdf-input-section">

              <label className="custom-checkbox-export-pdf title-and-subtitle">
                <input
                  type="checkbox"
                  checked={includeTitleAndSubtitle}
                  onChange={(e) => setIncludeTitleAndSubtitle(e.target.checked)}
                />
                <span className="checkmark-export-pdf"></span>
                Adicionar título e subtítulo
              </label>
              
              {includeTitleAndSubtitle && (
                <>
                  <div className="container-export-excel">
                    <div className="filter-email-export-excel">
                      <div className="filter-email-export-excel-text">Título do PDF:</div>
                      <input
                          type="text"
                          id="pdfTitle"
                          placeholder="Digite o título"
                          value={pdfTitle}
                          onChange={(e) => setPdfTitle(e.target.value)}
                          className="email-input-export-excel"
                      />
                    </div>
                  </div>

                  <div className="container-export-excel">
                    <div className="filter-email-export-excel">
                      <div className="filter-email-export-excel-text">Subtítulo do PDF:</div>
                      <textarea
                          type="text"
                          id="pdfSubtitle"
                          placeholder="Digite o subtítulo"
                          value={pdfSubtitle}
                          onChange={(e) => setPdfSubtitle(e.target.value)}
                          className="email-input-export-excel"
                      />
                    </div>
                  </div>
                  </>
                )}

                <div className="sort-options-export-pdf">
                  <h4 className="sort-title-export-pdf">Ordenar por:</h4>
                    <label className="custom-checkbox-export-pdf">
                      <input
                        type="radio"
                        name="sortOption"
                        value="veiculo"
                        checked={sortOption === 'veiculo'}
                        onChange={(e) => setSortOption(e.target.value)}
                      />
                      <span className="checkmark-export-pdf"></span>
                      Veículo
                    </label>
                    <label className="custom-checkbox-export-pdf">
                      <input
                        type="radio"
                        name="sortOption"
                        value="dataRecente"
                        checked={sortOption === 'dataRecente'}
                        onChange={(e) => setSortOption(e.target.value)}
                      />
                      <span className="checkmark-export-pdf"></span>
                      Data Mais Recente
                    </label>
                    <label className="custom-checkbox-export-pdf">
                      <input
                        type="radio"
                        name="sortOption"
                        value="dataAntiga"
                        checked={sortOption === 'dataAntiga'}
                        onChange={(e) => setSortOption(e.target.value)}
                      />
                      <span className="checkmark-export-pdf"></span>
                      Data Mais Antiga
                    </label>
                  </div>

                <div className='container-button-export-excel'>
                  <button className="generate-button-export-excel" onClick={handleGeneratePDF}>
                      Gerar PDF
                  </button>
                </div>
            </div>
          )}
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
}

export default ExportModal;
